import React from "react";

const Insta = (style = {}, fill = "#ffff", width = "100%", height) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    height={height}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M5.583 13a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v9a1 1 0 0 1-1 1zM8.958 13a1 1 0 0 1-1-1V2.25a1 1 0 1 1 2 0V12a1 1 0 0 1-1 1zM12.333 13a1 1 0 0 1-1-1V2.25a1 1 0 1 1 2 0V12a1 1 0 0 1-1 1z" />
    <path d="M37.292 48H11.917C4.344 48 0 44.014 0 37.062V12.125C0 4.873 4.27 0 10.625 0h27.584C42.918 0 48 4.524 48 11.834v25.083C48 43.132 43.297 48 37.292 48zM10.625 2C4.259 2 2 7.454 2 12.125v24.938C2 44.449 7.393 46 11.917 46h25.375C42.257 46 46 42.095 46 36.917V11.834C46 5.76 41.956 2 38.209 2H10.625z" />
    <path d="M23.999 15.25a7.667 7.667 0 1 0 0 15.334 7.667 7.667 0 0 0 0-15.334zm0 11.5a3.834 3.834 0 1 1 0-7.667 3.834 3.834 0 0 1 0 7.667z" />
    <path d="M23.999 35.084c-6.709 0-12.167-5.458-12.167-12.167S17.29 10.75 23.999 10.75s12.167 5.458 12.167 12.167-5.458 12.167-12.167 12.167zm0-22.334c-5.606 0-10.167 4.561-10.167 10.167s4.561 10.167 10.167 10.167 10.167-4.561 10.167-10.167S29.605 12.75 23.999 12.75z" />
    <path d="M32.999 14.92h14v2.16h-14zM1.499 14.949h13.25v2.102H1.499zM41.333 10.133a2.201 2.201 0 0 1-2.202 2.201h-3.264a2.201 2.201 0 0 1-2.202-2.201V6.867c0-1.215.985-2.201 2.202-2.201h3.264c1.217 0 2.202.986 2.202 2.201v3.266z" />
  </svg>
);

export default Insta;
