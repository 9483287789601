import React from "react";
import styled from "styled-components";

const StyledTent = styled.svg`
  margin-left: 20px;
  margin-top: 6px;
`;

const Tent = ({ style = {}, fill, width = "100%", height }) => (
  <StyledTent
    fill={fill}
    width={width}
    style={style}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ff641a"
      d="M328.598 23.398L256 133 183.402 23.398l25.196-16.8L256 79l47.402-72.402zm0 0"
    />
    <path fill="#ff641a" d="M241 0h30v106h-30zm0 0" />
    <path fill="#f03800" d="M256 0h15v106h-15zm0 0" />
    <path fill="#f03800" d="M328.598 23.398L256 133V79l47.402-72.402zm0 0" />
    <path
      fill="#f4d7af"
      d="M477.398 486.8H34.602l139.8-261.1 24.899-17.4L226 234.7l30-30 30 30 27-27 24.598 18zm0 0"
    />
    <path
      fill="#dabba6"
      d="M477.398 486.8H256V204.7l30 30 27-27 24.598 18zm0 0"
    />
    <path
      fill="#ff641a"
      d="M256 74.5l-81.598 151.2L226 277.3l30-30 30 30 51.598-51.6zm0 0"
    />
    <path fill="#f03800" d="M286 277.3l-30-30V74.5l81.598 151.2zm0 0" />
    <path
      fill="#faecd8"
      d="M363.7 435.5l-25.802 51.3H174.102L148.3 435.5 256 291.098zm0 0"
    />
    <path fill="#f4d7af" d="M363.7 435.5l-25.802 51.3H256V291.099zm0 0" />
    <path fill="#895d3c" d="M301.602 486.8h-91.204L256 291.099zm0 0" />
    <path fill="#714c2f" d="M301.602 486.8H256V291.099zm0 0" />
    <path fill="#ffda2d" d="M0 482h512v30H0zm0 0" />
    <path fill="#eebf00" d="M256 482h256v30H256zm0 0" />
  </StyledTent>
);

export default Tent;
