import React from "react";
import styled from "styled-components";

const StyledBeachSvg = styled.svg`
  margin-left: 20px;
`;

const Beach = ({ style = {}, fill, width = "100%", height }) => (
  <StyledBeachSvg
    style={style}
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 512 512.008"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#f3d55b"
      d="M407.422 333.492a196.808 196.808 0 0 1-11.52 42.496H22.48a196.808 196.808 0 0 1-11.52-42.496zm0 0"
    />
    <path
      fill="#f0c419"
      d="M395.902 375.988a190.596 190.596 0 0 1-22.87 42.496H45.351a190.596 190.596 0 0 1-22.872-42.496zm0 0"
    />
    <path
      fill="#f29c1f"
      d="M373.031 418.484a195.06 195.06 0 0 1-40.449 42.496H85.801a195.06 195.06 0 0 1-40.45-42.496zm0 0"
    />
    <path
      fill="#e57e25"
      d="M332.582 460.98c-72.504 56.66-174.277 56.66-246.781 0zm0 0"
    />
    <path
      fill="#ff5364"
      d="M409.727 302.941a200.478 200.478 0 0 1-2.305 30.551H10.96C-.41 259.105 30.816 184.613 91.836 140.574c61.02-44.039 141.559-50.21 208.57-15.98 67.016 34.23 109.23 103.097 109.32 178.347zm0 0"
    />
    <path
      fill="#fb7b76"
      d="M209.191 512.008C93.988 512.086.465 418.895.125 303.69a206.436 206.436 0 0 1 33.742-113.39c1.09-1.766 2.38-3.633 3.66-5.496l1.278-1.86a8.542 8.542 0 0 1 14.746 1.094 8.536 8.536 0 0 1-.63 8.516l-1.35 1.953c-1.133 1.64-2.278 3.293-3.337 4.984a189.645 189.645 0 0 0-31.043 104.2c0 106.038 85.961 192 192 192 106.04 0 192-85.962 192-192a188.706 188.706 0 0 0-9.386-59.051 8.536 8.536 0 0 1 16.215-5.332 205.968 205.968 0 0 1 10.238 64.382c-.34 115.204-93.863 208.395-209.067 208.317zm0 0"
    />
    <path
      fill="#804537"
      d="M162.258 341.34h-.778a8.523 8.523 0 0 1-5.812-3.035 8.52 8.52 0 0 1-1.941-6.266c.082-.922 8.02-92.45-16.41-149.469-8.442-19.687-16.087-34.988-22.231-47.273-12.688-25.438-21.094-42.274-21.094-67.024 0-4.71 3.82-8.53 8.531-8.53a8.533 8.533 0 0 1 8.536 8.53c0 20.747 6.824 34.477 19.328 59.395 6.246 12.492 14.02 28.039 22.648 48.18 26.145 61.011 18.082 153.812 17.758 157.73a8.53 8.53 0 0 1-8.535 7.762zm0 0"
    />
    <path
      fill="#955ba5"
      d="M329.426 332.809a43.583 43.583 0 0 0 3.5-17.067c0-23.566-19.106-42.668-42.668-42.668s-42.668 19.102-42.668 42.668a43.583 43.583 0 0 0 3.5 17.067"
    />
    <g fill="#24ae5f">
      <path d="M34.258 162.14a8.537 8.537 0 0 1-8.492-9.438c9.168-82.456 74.89-92.78 75.547-92.874a8.533 8.533 0 0 1 2.45 16.887c-2.306.36-53.376 9.012-61.025 77.832a8.528 8.528 0 0 1-8.48 7.594zm0 0" />
      <path d="M17.191 110.941c-.628 0-1.253-.07-1.867-.203-4.59-1.031-7.48-5.582-6.46-10.172.34-1.57 8.753-38.07 37.34-50.039 17.483-7.324 37.983-3.8 60.87 10.512 4.004 2.496 5.227 7.77 2.73 11.777a8.553 8.553 0 0 1-11.777 2.73C80.11 64.368 64.961 61.235 52.86 66.278c-20.402 8.535-27.254 37.743-27.304 38.035a8.535 8.535 0 0 1-8.364 6.63zm0 0" />
      <path d="M187.86 128.008a8.531 8.531 0 0 1-7.989-5.52c-.937-2.484-23.672-60.68-75-46.011a8.54 8.54 0 0 1-10.312-5.922 8.537 8.537 0 0 1 5.62-10.48c53.239-15.223 85.86 30.241 95.676 56.405a8.533 8.533 0 0 1-7.996 11.527zm0 0" />
      <path d="M102.523 76.809a8.438 8.438 0 0 1-4.222-1.125 8.533 8.533 0 0 1-3.184-11.676 60.022 60.022 0 0 1 41.813-32.426c32.66-5.7 63.718 20.555 65.023 21.676a8.534 8.534 0 0 1 .774 11.887 8.533 8.533 0 0 1-11.864 1.074c-.265-.215-26.37-22.117-51.074-17.817A44.009 44.009 0 0 0 109.922 72.5a8.533 8.533 0 0 1-7.399 4.309zm0 0" />
      <path d="M102.523 76.809a8.524 8.524 0 0 1-7.105-3.797C65.551 28.238 14.18 49.594 12.012 50.516A8.53 8.53 0 0 1 .07 41.672a8.529 8.529 0 0 1 5.218-6.84c22.926-9.82 74.195-16.453 104.336 28.707a8.533 8.533 0 0 1-7.102 13.27zm0 0" />
      <path d="M102.523 76.809a8.53 8.53 0 0 1-8.351-6.829 44.889 44.889 0 0 1-.555-3.414c-8.52-48.492-49.12-49.492-50.867-49.492a8.53 8.53 0 0 1-8.512-8.554A8.533 8.533 0 0 1 42.793.008a71.49 71.49 0 0 1 56.14 32.36A61.436 61.436 0 0 1 133.485.612a8.538 8.538 0 0 1 11.102 4.754 8.538 8.538 0 0 1-4.754 11.102c-19.977 7.203-32.16 27.433-29.184 48.46.11.708.223 1.419.325 2.133.656 4.579-2.45 8.848-7.008 9.626a8.446 8.446 0 0 1-1.442.12zm0 0M323.582 143.922a8.12 8.12 0 0 1-4.414-1.32l-.29-.188a7.763 7.763 0 0 1-2.558-1.59 8.527 8.527 0 0 1-2.46-11.515 8.536 8.536 0 0 1 11.386-3.008l1.277.77c.2.109.391.234.575.367.308.18.613.374.902.59a8.768 8.768 0 0 1 2.664 11.945 8.536 8.536 0 0 1-7.082 3.949zm0 0" />
      <path d="M358.45 247.305a7.963 7.963 0 0 1-1.852-.207 8.53 8.53 0 0 1-6.489-10.168c15.004-67.688-30.668-92.383-32.613-93.399a8.531 8.531 0 1 1-.738-14.762 8.523 8.523 0 0 1 8.52-.43c.597.31 59.445 31.329 41.492 112.274a8.54 8.54 0 0 1-8.32 6.692zm0 0" />
      <path d="M391.129 204.383a7.1 7.1 0 0 1-.953-.063c-4.672-.515-8.043-4.715-7.543-9.386 0-.297 3.012-30.18-13.551-44.825-9.797-8.66-25.176-10.613-45.742-5.836a8.534 8.534 0 0 1-3.871-16.613c26.289-6.137 46.812-2.851 60.996 9.735 23.16 20.566 19.308 57.832 19.129 59.41a8.532 8.532 0 0 1-8.465 7.578zm0 0" />
      <path d="M224.098 165.332a8.534 8.534 0 0 1-6.586-13.96c17.75-21.587 63.332-54.048 108.765-22.427a8.537 8.537 0 0 1-1.078 14.946 8.536 8.536 0 0 1-8.66-.942c-44.039-30.61-84.152 17.211-85.844 19.27a8.526 8.526 0 0 1-6.597 3.113zm0 0" />
      <path d="M321.395 144.484a8.532 8.532 0 0 1-8.372-6.925 43.921 43.921 0 0 0-20.48-32.48c-22.117-12.118-53.7.23-54.016.359a8.532 8.532 0 0 1-10.601-4.95 8.532 8.532 0 0 1 4.304-10.879c1.598-.648 39.457-15.421 68.54.52a60.04 60.04 0 0 1 29.058 44.226 8.54 8.54 0 0 1-6.77 9.997 8.778 8.778 0 0 1-1.663.132zm0 0" />
      <path d="M418.504 150.613a8.534 8.534 0 0 1-5.692-2.175c-1.765-1.579-43.707-38.254-86.183-5.711a8.53 8.53 0 0 1-8.465 1.105 8.525 8.525 0 0 1-5.187-6.773 8.537 8.537 0 0 1 3.273-7.883c43.094-33.016 89.5-10.137 108.016 6.574a8.534 8.534 0 0 1-5.727 14.863zm0 0" />
      <path d="M321.395 144.484a8.531 8.531 0 0 1-7.594-12.406c.344-.664.683-1.312 1.023-1.953 9.602-18.93 4.613-41.996-11.945-55.27a8.53 8.53 0 0 1-3.219-8.19 8.544 8.544 0 0 1 5.727-6.68c3-.993 6.3-.25 8.586 1.933a61.45 61.45 0 0 1 22.425 41.215 71.464 71.464 0 0 1 63.59-12.457 8.534 8.534 0 0 1-5.488 16.16c-1.707-.555-40.402-12.738-64.11 30.336a49.372 49.372 0 0 1-1.628 3.074 8.538 8.538 0 0 1-7.367 4.238zm0 0" />
    </g>
    <path
      fill="#804537"
      d="M189.566 341.34a8.532 8.532 0 0 1-8.082-11.262c11.094-32.937 30.106-78.508 57.293-108.715 14.684-16.215 27.067-28.398 37.008-38.18 19.89-19.546 30.848-30.316 37.547-49.988a8.536 8.536 0 0 1 6.422-5.625 8.542 8.542 0 0 1 8.078 2.75 8.532 8.532 0 0 1 1.664 8.371c-8.008 23.5-21.43 36.696-41.738 56.66-9.79 9.63-21.965 21.602-36.301 37.454-25.234 27.992-43.18 71.324-53.762 102.734a8.525 8.525 0 0 1-8.129 5.8zm0 0"
    />
  </StyledBeachSvg>
);

export default Beach;
