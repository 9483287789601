import React from "react";
import styled from "styled-components";

const StyledMagic = styled.svg`
  margin-left: 20px;
  /* margin-top: 22px; */
`;

const Magic = ({ style = {}, fill, width = "100%", height }) => (
  <StyledMagic
    fill={fill}
    width={width}
    style={style}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#a66300"
      d="M333.411 188.132c-6.534-6.534-17.132-6.534-23.666 0L14.443 483.433c-6.535 6.535-6.535 17.131 0 23.666A16.678 16.678 0 0 0 26.277 512c4.283 0 8.566-1.634 11.832-4.901l295.302-295.301c6.536-6.535 6.535-17.132 0-23.666z"
    />
    <path
      fill="#875100"
      d="M333.411 188.132c-.297-.297-.611-.566-.925-.836L13.607 506.175c.27.312.539.627.836.924A16.678 16.678 0 0 0 26.277 512c4.283 0 8.566-1.634 11.832-4.901l295.302-295.301c6.536-6.535 6.535-17.132 0-23.666z"
    />
    <path
      fill="#face17"
      d="M454.32 219.728l-38.766-51.947 20.816-61.386a16.735 16.735 0 0 0-21.223-21.223l-61.385 20.816-51.95-38.766a16.732 16.732 0 0 0-17.605-1.499 16.737 16.737 0 0 0-9.136 15.125l.829 64.815-52.924 37.426a16.736 16.736 0 0 0 4.694 29.645l61.898 19.241 19.24 61.897a16.735 16.735 0 0 0 29.645 4.695l37.426-52.924 64.815.828c6.322.051 12.233-3.462 15.125-9.136a16.732 16.732 0 0 0-1.499-17.607z"
    />
    <path
      fill="#e6bd15"
      d="M160.014 42.848l13.359 24.426 24.426 13.359-24.426 13.358-13.359 24.426-13.358-24.426-24.426-13.358 24.426-13.359z"
    />
    <g fill="#face17">
      <path d="M352.14 364.732l10.806 19.757 19.757 10.805-19.757 10.806-10.806 19.757-10.805-19.757-19.758-10.806 19.758-10.805zM367.337 0l10.805 19.757L397.9 30.563l-19.758 10.805-10.805 19.758-10.806-19.758-19.756-10.805 19.756-10.806zM484.168 130.689l6.467 11.825 11.824 6.466-11.824 6.467-6.467 11.824-6.466-11.824-11.825-6.467 11.825-6.466z" />
    </g>
    <g fill="#e6bd15">
      <path d="M492.627 294.117l-7.835 26.749 7.835 26.749-26.75-7.835-26.748 7.835 7.834-26.749-7.834-26.749 26.748 7.834zM455.82 237.334a16.734 16.734 0 0 0-1.499-17.606l-38.766-51.947 20.816-61.387a16.735 16.735 0 0 0-4.928-18.054L288.228 231.555l1.343.417 19.24 61.898a16.735 16.735 0 0 0 29.645 4.695l37.426-52.924 64.815.828c6.32.053 12.231-3.46 15.123-9.135z" />
    </g>
  </StyledMagic>
);

export default Magic;
