import React from "react";
import styled, { keyframes } from "styled-components";

const BouncedArrow = styled.svg`
  animation: ${keyframes`
      0%,
      60%,
      100% {
        transform: translate3d(0, 0, 0);
      }
      80% {
        transform: translate3d(0, 2px, 0);
      }
      70%,
      90% {
        transform: translate3d(0, 7px, 0);
      }
    `} infinite 2.0s ease-in-out;
    };
`;

const ArrowDown = (style = {}, fill, width = "100%", height) => (
  <BouncedArrow
    fill={fill}
    width={width}
    height={height}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 386.257 386.257"
  >
    <path d="M0 96.879l193.129 192.5 193.128-192.5z" />
  </BouncedArrow>
);

export default ArrowDown;
