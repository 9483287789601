import React from "react";
import styled from "styled-components";

const StyledCrypto = styled.svg`
  margin-left: 20px;
  margin-top: 6px;
`;

const Crypto = ({ style = {}, fill, width = "100%", height }) => (
  <StyledCrypto
    fill={fill}
    width={width}
    style={style}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ff9f00"
      d="M512 272v60l-20.7 25.2H376.099L362 332v-60l35.7-32.7c-1.5-1.5 17.1 0 39.3 2.098 29.7 3 65.7 7.2 65.7 7.2zm0 0"
    />
    <path
      fill="#ff7816"
      d="M512 272v60l-20.7 25.2H437V241.397c29.7 3 65.7 7.2 65.7 7.2zm0 0"
    />
    <path
      fill="#e63950"
      d="M451 15v60c0 8.398-6.598 15-15 15s-15-6.602-15-15V51.3L266.5 206.5c-2.7 3-6.602 4.5-10.5 4.5s-7.8-1.5-10.5-4.5L196 157.3 86.5 266.5c-2.7 3-6.602 4.5-10.5 4.5s-7.8-1.5-10.5-4.5c-6-5.7-6-15.3 0-21l120-120c5.7-6 15.3-6 21 0l49.5 49.2L399.7 30H376c-8.402 0-15-6.602-15-15 0-8.402 6.598-15 15-15h60c8.402 0 15 6.598 15 15zm0 0"
    />
    <g fill="#ff9f00">
      <path d="M150 407v45s-54.3 28.2-57.3 28.2c-1.2 0-9 .3-17.7.6-12.598 0-27.3.298-27.3.298L0 452v-45c0-8.402 6.598-15 15-15h120c8.402 0 15 6.598 15 15zm0 0M331 392v60l-22.5 31.8h-94.8L181 452v-60l26.398-15.902H303.7zm0 0M512 392v60l-16.8 23.398H391.097L362 452v-60l19.8-18.602h105zm0 0" />
    </g>
    <path
      fill="#cc2e43"
      d="M451 15v60c0 8.398-6.598 15-15 15s-15-6.602-15-15V51.3L266.5 206.5c-2.7 3-6.602 4.5-10.5 4.5v-36.3L399.7 30H376c-8.402 0-15-6.602-15-15 0-8.402 6.598-15 15-15h60c8.402 0 15 6.598 15 15zm0 0"
    />
    <path
      fill="#ff7816"
      d="M331 392v60l-22.5 31.8H256V376.099h47.7zm0 0M486.8 373.398L512 392v60l-16.8 23.398H437v-102zm0 0M150 407v45s-54.3 28.2-57.3 28.2c-1.2 0-9 .3-17.7.6V392h60c8.402 0 15 6.598 15 15zm0 0"
    />
    <path
      fill="#ffd400"
      d="M512 452v45c0 8.398-6.598 15-15 15H377c-8.402 0-15-6.602-15-15v-45zm0 0M331 452v45c0 8.398-6.598 15-15 15H196c-8.402 0-15-6.602-15-15v-45zm0 0M150 452v45c0 8.398-6.598 15-15 15H15c-8.402 0-15-6.602-15-15v-45zm0 0"
    />
    <path
      fill="#fdbf00"
      d="M256 452h75v45c0 8.398-6.598 15-15 15h-60zm0 0M437 452h75v45c0 8.398-6.598 15-15 15h-60zm0 0M150 452v45c0 8.398-6.598 15-15 15H75v-60zm0 0"
    />
    <path
      fill="#ffd400"
      d="M362 332h150v60H362zm0 0M331 347v45H181v-45c0-8.402 6.598-15 15-15h120c8.402 0 15 6.598 15 15zm0 0"
    />
    <path
      fill="#fdbf00"
      d="M331 347v45h-75v-60h60c8.402 0 15 6.598 15 15zm0 0M437 332h75v60h-75zm0 0"
    />
    <path
      fill="#ffd400"
      d="M512 212v60H362v-60c0-8.402 6.598-15 15-15h120c8.402 0 15 6.598 15 15zm0 0"
    />
    <path
      fill="#fdbf00"
      d="M512 212v60h-75v-75h60c8.402 0 15 6.598 15 15zm0 0"
    />
  </StyledCrypto>
);

export default Crypto;
