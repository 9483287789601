import React from "react";
import { Box, Text } from "./kit";
import { Link, Section, Tech, ImageWithBorder } from "./styles";
import { Beach, Crypto, Magic, Tent, Zombie } from "../svg-icons";
import { lottacoin2, urban, rt, yelpcamp, getpop } from "../images";

const Projects = () => {
  const projectsData = [
    {
      link: "https://getpop.co/",
      title: "Get Pop",
      icon: <Magic height="35px" width="35px" />,
      stack: "Sass, JavaScript, Liquid.",
      description:
        "I customized the layout and features of the e-commerce store built via Shopify. Implemented designs using Sass and JavaScript.",
      image: `${getpop}`,
      alt: "Get Pop home page",
    },
    {
      link: "https://rotten-oranges.now.sh/",
      title: "Zombie apocalypse",
      icon: <Zombie height="40px" width="36px" />,
      stack: "React, Redux, styled-components.",
      description: "Rotten Oranges algorithm visualization.",
      image: `${rt}`,
      alt: "Rotten Oranges algorithm visualization",
    },
    {
      link: "https://hidden-citadel-48758.herokuapp.com/",
      title: "Urban Miami",
      icon: <Beach height="40px" width="40px" />,
      stack: "Ruby on Rails, Stripe, Cloudinary, bcrypt.",
      description: "E-commerce application.",
      image: `${urban}`,
      alt: "Urban Miami Online store",
      imageWithBorder: true,
    },
    {
      link: "https://lottacoin.herokuapp.com/",
      title: "Lotta Coin",
      icon: <Crypto height="30px" width="30px" />,
      stack: "Ruby on Rails, CryptoCompare API, bcrypt.",
      description: "A simple cryptocurrency tracker.",
      image: `${lottacoin2}`,
      alt: "Lotta Coin home page screenshot",
    },
    {
      link: "https://immense-chamber-81812.herokuapp.com/",
      title: "YelpCamp",
      icon: <Tent height="30px" width="30px" />,
      stack: "Node.js, Express.js, MongoDB.",
      description: "A blog application for camping listings.",
      image: `${yelpcamp}`,
      alt: "Yelp Camp main page screenshot",
      imageWithBorder: true,
    },
  ];

  return (
    <>
      {projectsData.map((data) => {
        const {
          link,
          title,
          icon,
          stack,
          description,
          image,
          alt,
          imageWithBorder,
        } = data;

        return (
          <Section key={title}>
            <Box display="flex">
              <Link
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                primary
              >
                {title}
              </Link>
              {icon}
            </Box>
            <Box>
              <Tech mt={0}>Technologies used: {stack}</Tech>
              <Text mb={4}>{description}</Text>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {imageWithBorder ? (
                  <ImageWithBorder src={image} width="100%" alt={alt} />
                ) : (
                  <img src={image} width="100%" alt={alt} />
                )}
              </a>
            </Box>
          </Section>
        );
      })}
    </>
  );
};

export default Projects;
