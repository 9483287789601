import React from "react";
import styled from "styled-components";

const StyledBeachSvg = styled.svg`
  margin-left: 20px;
`;

const Zombie = ({ style = {}, fill = "#ffff", width = "100%", height }) => (
  <StyledBeachSvg
    fill={fill}
    width={width}
    style={style}
    height={height}
    viewBox="0 0 511.968 511.968"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#e24b4b"
      d="M253.264 34.992c-6.464.192-12.848-.976-19.152-14.432-11.12-18.608-21.984.24-32.56-8.608-10.56 4.976-50.352 22.672-59.616 29.712-4.64 6.608-22.464 28.928-26.736 30.464-8.544 6.032-29.568 12.912-46.48 17.92-15.872 4.704-25.296 25.472-28.064 35.808-6.176 25.008-28.8 75.344-32.192 89.2-1.632 4.496-.624 9.264 1.536 13.776h243.264V34.992z"
    />
    <path
      fill="#8cc63e"
      d="M511.68 268.16c-6.08 130.4-113.44 237.76-243.84 243.52C120.96 518.4 0 401.44 0 256c0-16.64 1.6-33.12 4.64-48.8.96-5.44 4.96-9.76 10.08-11.68 87.36-32.96 148.64-99.84 184.64-182.24 2.24-4.96 6.4-8.48 11.68-9.44C225.6 1.28 240.64 0 256 0c145.28 0 262.544 121.28 255.68 268.16z"
    />
    <path
      fill="#7fb239"
      d="M511.968 255.968c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72 58.08 46.96 95.28 118.8 95.28 199.28z"
    />
    <path
      fill="#3e4347"
      d="M347.424 428.368c-60.96-16.272-121.936-16.272-182.896 0-8.768 2.384-16.784-5.264-14.896-14.144 10.144-49.328 53.952-86.496 106.288-86.496 52.448 0 96.144 37.184 106.416 86.496 1.856 8.88-6.144 16.528-14.912 14.144z"
    />
    <path
      fill="#e24b4b"
      d="M317.952 421.92c-.896-12.528-5.44-24.208-12.864-33.648-8.256-10.688-23.152-15.04-35.44-9.52-8.224 3.6-17.792 5.36-28.768 4.384l-.32-.032c-20.832-1.952-40.768 10.48-44.864 31.024-.48 2.48-.704 5.056-.896 7.648 41.056-7.312 82.112-7.28 123.152.144z"
    />
    <g fill="#fff">
      <path d="M345.952 375.744c-19.52-28.912-52.496-48.016-90.048-48.016-37.472 0-70.496 19.12-90 48.048 60.032-23.952 120.048-23.952 180.048-.032z" />
      <path d="M312.288 388.384c-.256-20.912-19.024-42.192-19.024-42.192l23.408 1.504c9.872 26.288-4.384 40.688-4.384 40.688zM199.664 388.384c.256-20.912 19.024-42.192 19.024-42.192l-23.408 1.504c-9.888 26.288 4.384 40.688 4.384 40.688zM187.152 392.448s-11.136 11.344-6.8 32.224a350.781 350.781 0 0116.448-3.216c-4.736-7.936-9.52-18.512-9.648-29.008zM315.136 421.456c5.488.944 10.96 2 16.448 3.216 4.336-20.864-6.8-32.224-6.8-32.224-.112 10.496-4.896 21.072-9.648 29.008z" />
      <path d="M254.992 403.216c-28.592 0-54.368 2.544-72.768 8.608 1.28 3.808 2.144 7.76 2.8 11.744 47.12-9.76 94.224-9.808 141.344-.128 1.296-3.6 2.704-7.168 4.576-10.48-18.56-6.864-45.664-9.744-75.952-9.744z" />
    </g>
    <path
      fill="#e24b4b"
      d="M324.592 469.616c-.976-4.224 2.512-31.2 2.384-31.664-.608-2.128-1.936-1.872-3.76-1.504-2.128.368-4.256-1.376-4.256-3.504-.688 3.968-1.04 13.344-4.88 13.776-1.504.128-2.88-1.008-3.376-2.496-.992-2.192 2.448-42.496 2.512-42.688 1.376-3.888 3.888 1.872 6.128 1.872 1.504 0 2.752-1.632 4.128-1.008 1.552.784.368 2.992.368 4.624 0 2 1.872 3.376 3.376 4.752 17.04 15.328-2.16 34.816 6.64 49.952 1.12 2.128 2.752 3.888 4 5.888 6.736 11.456-10.704 15.824-13.264 2z"
    />
    <g fill="#fff">
      <ellipse cx="157.84" cy="237.968" rx="64.816" ry="64.88" />
      <ellipse cx="354.064" cy="237.968" rx="64.816" ry="64.88" />
    </g>
    <path
      fill="#e24b4b"
      d="M39.168 173.072V267.472s7.056 17.056 17.536 3.024-3.536-12.72-3.536-32.544-3.664-34.816 12-44.144 27.328-8 35.008-27.008 15.328-2.672 8 14-5.072 30.672 2.96 30.672 12.032-4 8.368-15.008-4.672-38.672-.336-50S202.592 52.64 199.552 12.88l-87.04 93.904"
    />
    <path
      fill="#3e4347"
      d="M387.84 218.72c0 17.92-14.56 32.48-32.48 32.48s-32.48-14.56-32.48-32.48c0-18.08 14.56-32.64 32.48-32.64s32.48 14.56 32.48 32.64z"
    />
    <path
      fill="#fff"
      d="M375.024 215.088c-2.208 2.208-7.728.48-12.128-3.936-4.416-4.416-6.144-9.92-3.936-12.128 2.368-2.368 7.872-.64 12.288 3.776s6.144 9.936 3.776 12.288z"
    />
    <path
      fill="#3e4347"
      d="M187.312 257.632c0 17.92-14.56 32.48-32.48 32.48s-32.48-14.56-32.48-32.48c0-18.08 14.56-32.64 32.48-32.64 17.936 0 32.48 14.56 32.48 32.64z"
    />
    <path
      fill="#fff"
      d="M174.512 254c-2.208 2.208-7.728.48-12.128-3.936-4.416-4.416-6.144-9.92-3.936-12.128 2.368-2.368 7.872-.64 12.288 3.776 4.416 4.416 6.144 9.92 3.776 12.288z"
    />
    <path
      fill="#3e4347"
      d="M243.84 43.296c42.976-.224 142.496 28.8 193.008 110-24.944-20.56-66.288-61.024-193.008-110z"
    />
    <g fill="#e24b4b">
      <path d="M395.2 112.448c.896-1.92-92.464-53.408-94.848-52.336 30.384 7.392 73.696 31.232 94.848 52.336z" />
      <path d="M336.16 75.376s5.888 17.696 4.176 30.688c-1.728 12.992-.784 28.048 3.36 37.84s14.064 3.2 10.416-3.008c-3.648-6.208-6.656-10.736-2.704-19.392s6.032-12.8 6.592-16.56c.56-3.76 5.648 0 4.144 5.264s-3.392 10.544-1.888 14.112c1.504 3.584 4.336.384 8.464 2.64 4.128 2.256 4.176 4.24 7.36 7.2s6 2.768 6 9.36-3.008 13.936-1.504 23.536c1.504 9.6-.944 14.896 7.248 19.024s10.816-6.032 4.8-14.496c-6.016-8.464-9.792-21.088-5.84-33.136s12.608-21.088 7.152-27.296c-5.44-6.208-57.776-35.776-57.776-35.776z" />
    </g>
    <g fill="#3e4347">
      <path d="M277.76 34.56c-6.128 7.44-13.584 22.864-21.792 30.8.976-12.528 12.64-29.264 21.792-30.8zM407.088 149.904c5.68-7.376 12.96-23.376 20.8-31.488-.608 13.136-12.224 29.76-20.8 31.488zM311.392 43.296c-16.816 9.408-24.32 24.528-33.632 40.096.4-20.304 18.96-40.192 33.632-40.096zM378.32 134.16c16.208-9.072 24.304-24.496 33.632-40.096-.4 20.192-18.928 40.192-33.632 40.096zM337.552 54.624c-16 9.552-23.312 22.448-33.136 36.624 1.072-19.424 20.176-36.752 33.136-36.624zM350.48 113.04c16-9.552 23.52-22.736 33.136-36.624-1.12 20.416-20.848 36.736-33.136 36.624z" />
    </g>
  </StyledBeachSvg>
);

export default Zombie;
