import styled from "styled-components";
import {
  margin,
  space,
  color,
  fontSize,
  width,
  height,
  minHeight,
  fontWeight,
  lineHeight,
  borderRadius,
  border,
  background,
  backgroundColor,
  letterSpacing,
  textAlign,
  borderBottom,
  position,
  top,
  gridColumnGap,
  gridTemplateColumns,
  display,
  justifyItems,
  alignItems,
  justifyContent,
  backgroundImage,
} from "styled-system";

export const Img = styled.img`
  ${width}
  ${height}
`;
Img.propTypes = {
  ...width.propTypes,
  ...height.propTypes,
};

export const Link = styled.a`
  text-decoration: none;
  color: white;
`;

export const Box = styled.div`
  ${space}
  ${width}
  ${height}
  ${minHeight}
  ${fontSize}
  ${color}
  ${backgroundColor}
  ${letterSpacing}
  ${textAlign}
  ${borderBottom}
  ${position}
  ${top}
  ${gridColumnGap}
  ${gridTemplateColumns}
  ${display}
  ${justifyItems}
  ${alignItems}
  ${justifyContent}
  ${lineHeight}
  ${backgroundImage}
`;
Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...minHeight.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...backgroundColor.propTypes,
  ...letterSpacing.propTypes,
  ...textAlign.propTypes,
  ...borderBottom.propTypes,
  ...position.propTypes,
  ...top.propTypes,
  ...gridColumnGap.propTypes,
  ...gridTemplateColumns.propTypes,
  ...display.propTypes,
  ...justifyItems.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...lineHeight.propTypes,
  ...backgroundImage.propTypes,
};

export const Text = styled.p`
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${color}
  ${textAlign}
  ${letterSpacing}
  ${width}
  ${margin}
`;
Text.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...letterSpacing.propTypes,
  ...width.propTypes,
  ...margin.propTypes,
};

export const Button = styled.button`
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${borderRadius}
  ${border}
  ${background}
  ${fontWeight}
  ${backgroundColor}
  ${letterSpacing}
`;

Button.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...borderRadius.propTypes,
  ...border.propTypes,
  ...background.propTypes,
  ...fontWeight.propTypes,
  ...backgroundColor.propTypes,
  ...letterSpacing.propTypes,
};

export const Heading = Text.withComponent("h1");
