import React from "react";
import styled from "styled-components";
import { Box } from "./kit";
import { Github, Twitter, Insta, LinkedIn } from "../svg-icons";

const StyledBox = styled(Box)`
  .last {
    margin-right: 0 !important;
  }
`;

const StyledList = styled.ul`
  padding-left: 0;
  list-style: none;
  display: flex;

  max-width: 400px;
  margin: auto;
  padding-top: 24px;
`;

const ListItem = styled.li`
  margin: auto;
`;

const Link = styled.a`
  display: inline-block;
  a:hover {
    svg {
      fill: hotpink;
    }
  }
`;

const Social = () => (
  <StyledBox>
    <StyledList>
      <ListItem>
        <Link
          height="32px"
          href="https://github.com/khristinasar"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Github height="32px" />
        </Link>
      </ListItem>
      <ListItem>
        <Link
          href="https://www.linkedin.com/in/khristinasheer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIn height="32px" />
        </Link>
      </ListItem>
      <ListItem>
        <Link
          href="https://www.instagram.com/acodingnomad/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Insta height="32px" fill="#ffff" />
        </Link>
      </ListItem>
    </StyledList>
  </StyledBox>
);

export default Social;
