import React from "react";
import styled from "styled-components";
import media from "../helpers/media";

const StyledSvg = styled.svg`
  cursor: pointer;

  cursor: pointer;
  width: 18px;
  height: 18px;

  ${media.up.large`
    width: 30px;
    height: 30px;
  `};
`;

const LeftArrow = ({ style = {}, fill = "#ffff", width = "100%", height }) => (
  <StyledSvg
    fill={fill}
    style={style}
    viewBox="0 0 551.13 551.13"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m189.451 275.565 223.897-223.897v-51.668l-275.565 275.565 275.565 275.565v-51.668z" />
  </StyledSvg>
);

export default LeftArrow;
