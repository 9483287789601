import styled from "styled-components";
import media from "../helpers/media";
import { Box, Text } from "./kit";

export const Link = styled.a`
  font-size: ${(props) => (props.primary ? "32px" : "16px")};
  font-style: ${(props) => (props.italic ? "italic" : "none")};
  text-decoration: none;
  color: black;
`;

export const Tech = styled(Text)`
  font-style: italic;
  font-size: 14px;
  color: #696969;
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const ImageWithBorder = styled.img`
  border: 1px solid #d3d3d3;
  width: 100%;
`;

export const AwardText = styled(Text)`
  margin-right: 16px
  margin-left: 16px;

  ${media.down.tiny`
    margin-right: 48px
    margin-left: 48px;
  `};

  ${media.up.large`
    margin-right: 32px
    margin-left: 32px;
  `};

`;

export const LeftSide = styled(Box)`
  width: 100%;
  position: relative;

  ${media.up.ipadpro`
  width: 45%;
  position: fixed;
`};
`;

export const RightSide = styled(Box)`
  width: 100%;

  ${media.up.ipadpro`
    width: 50%;
    margin-left: 47%;
  `};
`;

export const SideSection = styled(Box)`
  ${media.down.small`
    margin-right: 32px;
    margin-left: 32px;
    padding-bottom: 96px;
  `};

  ${media.up.medium`
    margin-left: 72px;
    margin-right: 72px;
  `};

  ${media.up.large`
    margin-right: 78px;
    margin-left: 86px;
  `};

  ${media.up.xlarge`
    margin-right: 128px;
    margin-left: 128px;
  `};
`;

export const Section = styled.section`
  min-height: initial;
  padding-top: 148px;

  ${media.up.large`
    padding-top: 48px;
  `};

  ${media.up.xlarge`
    min-height: 100vh;
    padding-top: 96px;
  `};
`;

export const StyledCarousel = styled(Box)`
  ${media.down.tiny`
    margin-top: 20px;
    width: 306px;
    margin-left: -26px;
  `};

  ${media.up.xsmall`
    margin-top: 25px;
    width: 374px;
    margin-left: -31px;
  `};

  ${media.up.xphone`
    margin-top: 28px;
    width: 370px;
    margin-left: -29px;
  `};

  ${media.up.iplus`
    margin-top: 30px;
    width: 400px;
    margin-left: -25px;
  `};

  ${media.up.medium`
    margin-top: 48px;
    width: 670px;
    margin-left: -21px;
  `};

  ${media.up.ipadpro`
    width: 670px;
    margin-left: -41px;
  `};

  ${media.up.large`
    margin-top: 32px;
    width: 650px; 
    margin-left: -52px;
  `};

  ${media.up.xlarge`
    width: 684px;
    margin-left: -52px;
  `};
`;

export const StyledImg = styled.img`
  border: 1px solid #d3d3d3;

  ${media.up.large`
    display: none;
  `};
`;

export const Down = styled(Box)`
  cursor: pointer;

  ${media.up.ipadpro`
    display: none;
  `};

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  outline: none;
`;
