import { createGlobalStyle } from "styled-components";

export const theme = {
  fontSizes: [12, 14, 16, 18, 24, 32, 40, 48, 64, 96, 128],
  space: [
    0,
    8, // 1
    12,
    16, // 3
    32,
    48, // 5
    64,
    96,
    128,
    256,
    286
  ],
  fontWeights: [300, 400, 500, 600],
  colors: {
    blue: "#07c",
    red: "#e10"
  },
  radii: [0, 2, 4, 6, 8]
};

export const GlobalStyle = createGlobalStyle`
    html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body { 
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 300;
    font-family: Calibri, Helvetica, Arial, sans-serif;
    font-family: Circular Std,-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif;
    font-size: 16px;
  }
`;
