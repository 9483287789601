import React from "react";
import ReactDOM from "react-dom";
import Carousel, { consts } from "react-elastic-carousel";
import { ThemeProvider } from "styled-components";

import { theme, GlobalStyle } from "./components/theme";
import { Heading, Box, Text, Img } from "./components/kit";
import { ArrowDown, Magic, LeftArrow, RightArrow } from "./svg-icons";
import {
  Link,
  Down,
  Section,
  Button,
  StyledCarousel,
  Tech,
  SideSection,
  LeftSide,
  RightSide,
  AwardText,
} from "./components/styles";
import useScrollOut from "./hooks/useScrollOut";
import Social from "./components/social";
import { items } from "./components/data";
import Projects from "./components/projects";

const App = () => {
  const [wrapperRef, scrollDown] = useScrollOut();

  const handleScrollDown = () => {
    scrollDown();
  };

  const myArrow = ({ type, onClick }) => {
    const pointer =
      type === consts.PREV ? (
        <LeftArrow fill="black" />
      ) : (
        <RightArrow fill="black" />
      );
    return <Button onClick={onClick}>{pointer}</Button>;
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Box className="App" mx="auto">
          <Box textAlign="center" position="relative">
            <LeftSide
              ref={wrapperRef}
              pt="25vh"
              backgroundImage="linear-gradient(225deg,#1800c8 0%,#b900b4 100%)"
              height="100vh"
            >
              <Box textAlign="center">
                <Heading
                  mt={3}
                  mb={0}
                  color="white"
                  fontSize={6}
                  letterSpacing="0.5px"
                >
                  Khristina Sar
                </Heading>
                <Social />

                <Text mt={5} mx={[3, 8]} fontSize={3} color="white">
                  Skilled Front-end Developer with almost 3 years of experience
                  designing, developing, and managing complex sites and internal
                  frameworks.
                </Text>
                <Down onClick={handleScrollDown}>
                  <ArrowDown
                    fill="#ffff"
                    height="25px"
                    width="25px"
                    position="absolute"
                    bottom="120px"
                    marginLeft="-10px"
                  />
                </Down>
              </Box>
            </LeftSide>

            <RightSide>
              <SideSection textAlign="left">
                <Section>
                  <Box display="flex">
                    <Link
                      href="https://www.magicleap.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      primary
                    >
                      Magic Leap
                    </Link>
                    <Magic height="35px" width="35px" />
                  </Box>
                  <Box>
                    <Tech mt={0}>
                      Technologies used: React, Redux, styled-systems,
                      styled-components, react-imgix.
                    </Tech>
                    <Text mb={2}>
                      As a Front End Engineer, I was responsible for
                      implementing responsive styling of the pages according to
                      the mockups provided by designers, as well as improving
                      the site's performance and implementing new features.
                    </Text>
                    <Text mb={2}>
                      Awards, recognition, and mentions that our products
                      received:
                    </Text>
                    <StyledCarousel>
                      <Carousel itemsToShow={1} renderArrow={myArrow}>
                        {items.map((item) => (
                          <div key={item.id}>
                            <a
                              href={item.href}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Img
                                src={item.src}
                                width={["316px", "585px", "600px"]}
                                height={["200px", "370px", "370px"]}
                                alt={item.alt}
                              />
                            </a>
                            <Link
                              href={item.href}
                              target="_blank"
                              rel="noopener noreferrer"
                              italic
                            >
                              <AwardText fontSize={[0, 1]}>
                                {item.text}
                              </AwardText>
                            </Link>
                          </div>
                        ))}
                      </Carousel>
                    </StyledCarousel>
                  </Box>
                </Section>
                <Projects />
              </SideSection>
            </RightSide>
          </Box>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
