export default {
  up: {
    xlarge: 1680,
    large: 1440,
    ipadpro: 1366,
    medium: 768,
    small: 576,
    iplus: 414,
    xphone: 370,
    xsmall: 575,
  },
  down: {
    large: 1440,
    medium: 1023,
    small: 767,
    tiny: 320,
  },
};
