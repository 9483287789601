import { useCallback, useRef } from "react";

const useScrollOut = () => {
  const ref = useRef();

  const scroll = useCallback(
    () => {
      if (typeof window !== "undefined" && ref.current) {
        const { height, top: wrapperRef } = ref.current.getBoundingClientRect();

        window.scrollTo({
          behavior: "smooth",
          top: height + wrapperRef
        });
      }
    },
    [ref]
  );

  return [ref, scroll];
};

export default useScrollOut;
