import { conf, creator, whales, ml1 } from "../images";

export const items = [
  {
    id: 1,
    href: "https://www.awwwards.com/sites/magicleap",
    alt: "Awwwards",
    src: `${whales}`,
    text:
      '"Magic Leap One’s unique design and technology lets in natural light waves together" - Awwwards',
  },
  {
    id: 2,
    href: "https://www.naker.io/posts/cool-website-magic-leap",
    alt: "Cool Website: Magic Leap",
    src: `${conf}`,
    text: '"Cool Website: Magic Leap. Naker Blog"',
  },
  {
    id: 3,
    href: "https://www.cssdesignawards.com/sites/magic-leap/33633/",
    alt: "Website of the day",
    src: `${creator}`,
    text: '"Website of the Day 2018 September 15" - CSS Design Awards',
  },
  {
    id: 4,
    href: "https://thefwa.com/cases/magic-leap-enter-the-magicverse",
    alt: "FWA of the day",
    src: `${ml1}`,
    text: '"FWA OF THE DAY: September 2018. The FWA"',
  },
  {
    id: 5,
    href: "https://www.csswinner.com/details/magic-leap/13026",
    alt: "Site of the Month October 2018",
    src: `${conf}`,
    text: '"Site of the Month October 2018" - CSS Winner',
  },
  {
    id: 6,
    href:
      "https://www.webbyawards.com/winners/2019/websites/features-design/best-homepage/magic-leap/",
    alt: "The Webby Awards 2019 Honoree",
    src: `${whales}`,
    text: '"2019 Honoree" - The Webby Awards ',
  },
];
